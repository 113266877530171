import { useState, useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ExportPosts = () => {

    const [siteList, setSiteList] = useState([]);
    const [postsAdded, setPostsAdded] = useState('');
    const [siteId, setSiteId] = useState({ id: 0, label: 'Select' });
    const [siteIDSelected, setSiteIDSelected] = useState('');
    const [autoCompleteSiteList, setAutoCompleteSiteList] = useState([{ label: "Select ", id: 0 }]);

    useEffect(() => {
        fetchSiteList();
    }, []);
    async function fetchSiteList() {
        let response = await fetch('sites/getsites');
        let data = await response.json();

        setSiteList(data);
        let sites = [{ label: "Select ", id: 0 }];
        data.forEach(site => {

            const option = { label: site.name, id: site.id };
            sites.push(option);
        });
        setAutoCompleteSiteList(sites);
    } 

    const siteSelectionChanged = async (event, value) => {
        //setSiteName(value.label);
        setSiteId(value);

        
    }

    const searchChangeHandler = (event) => {
        setPostsAdded(event.target.value);
    }

    const submitPosts = async () => {
        const postsToSearch = postsAdded.split(",");
        
        for (let i = 0; i < postsToSearch.length; i++) {
            const post = await getPostsfromSite(postsToSearch[i]);
            
            if (post.length == 1) {
                await addLink(post);
                
            }
            else {
                console.log(postsToSearch[i]);
            }
        }

    }

    async function addLink(post_selected) {
        try {
            const data = { id: 0, post_url: post_selected[0].post_url, post_title: post_selected[0].post_title, site_id: siteId.id, wp_id: post_selected[0].wp_id, content: '', content_Editing: 1, status: 1, updated_by: 1, is_google_doc: 0, is_draft: 0 };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/upadatepost', config)

            if (response.ok) {
                const json = await response.json();
                console.log(json[0].id);
                return json[0].id;
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }

    async function getPostsfromSite(searchValue) {
        const data = { id: 0, post_id: 0, post_url: '', link: '', post_title: searchValue, site_id: siteId.id, wp_id: 0, content: '', updated_by: 0, is_google_doc: 0 };

        try {

            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/searchposts', config)

            if (response.ok) {
                const json = await response.json();
                //console.log(json);

                return json;

            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }
    return (
        <>
            <div className="col">

                <Autocomplete
                    autoSelect={true}
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    value={siteId}
                    inputValue={siteIDSelected}
                    onInputChange={(event, newInputValue) => {
                        setSiteIDSelected(newInputValue);
                    }}
                    //isOptionEqualToValue={(option, siteId) => option.id === siteId.id}
                    options={autoCompleteSiteList}
                    sx={{ width: 300 }}
                    onChange={siteSelectionChanged}
                    renderInput={(params) => <TextField {...params} label="Select Site" />}
                />
            </div>
            <input className="form-control" type="textarea" onChange={searchChangeHandler} name="searchBy" placeholder="Search By Title" ></input>
            <button onClick={ submitPosts}>Submit</button>
        </>
    );
}

export default ExportPosts;