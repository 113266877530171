import { useState, useEffect } from "react";
import DraftPostColumns from '../DraftPosts/DraftPostColumns';
import EditPostContent from "../ContentEditing/EditPostContent";
import SelectionPopUp from "../ContentEditing/SelectionPopUp";
import Card from '../../UI/Card';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { useSearchParams } from "react-router-dom";
import AddNewLinkPopUp from "../GoogleLinks/AddNewLinkPopUp";


const ContentEditing = (props) => {

    const [siteList, setSiteList] = useState([]);
    const [draftPosts, setDraftPosts] = useState([]);
    const [filteredDraftList, setFilteredDraftList] = useState([]);
    const [filteredSelectionList, setFilteredSelectionList] = useState([]);
    const [addPostImages, setAddPostImages] = useState(false);
    const [postSelected, setPostSelected] = useState([]);
    const [siteId, setSiteId] = useState({ id: 0, label: 'Select' });
    const [roleId, setRoleId] = useState(ReactSession.get("RoleID"));
    const [viewID, setViewId] = useState(ReactSession.get("RoleID"));
    const [statusFilter, setStatusFilter] = useState(0);
    const [autoCompleteSiteList, setAutoCompleteSiteList] = useState([{ label: "Select ", id: 0 }]);

    const [searchParams] = useSearchParams();
    const url_post_id = searchParams.get('postid');
    const url_site_id = searchParams.get('siteid');
    const is_google_doc = searchParams.get('is_google_doc');
    const url_viewid = searchParams.get('viewid');
    const navigate = useNavigate();
    const [siteIDSelected, setSiteIDSelected] = useState('');

    const [showAddNewLinkPopup, setShowAddNewLinkPopup] = useState(false);


    const [showSearchPopUp, setShowSearchPopUp] = useState(false);
    const [viewSelected, setViewSelected] = useState(0);

    const userId = ReactSession.get('UserID');
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        
        if (props.RoleId !== undefined) {
            setViewId(props.RoleId);
            ReactSession.set("view", props.RoleId);

        }
        else if (typeof ReactSession.get("view") != 'undefined') {
            
            setViewId(ReactSession.get("view"));
        }
        
        else if (ReactSession.get("RoleID") == 1) {
            
            setViewId(5);
        }
        else {
            setViewId(4);
        }
        if (url_viewid !== null) {
            console.log("view id " + url_viewid);
            setViewId(url_viewid);
        }
        fetchSiteList();
        if (url_site_id !== null) {
            fetchDraftPosts(url_site_id);
            const site = autoCompleteSiteList.filter(post => post.id == url_site_id);
            setSiteId(site[0]);

        }
        if (is_google_doc !== null) {
            if (is_google_doc == 1)
                setViewSelected(1);
        }
    }, []);

    //useEffect(() => { console.log("post selection changed !!")}, [postSelected]);
    useEffect(() => {
        filterList(statusFilter);

        if (draftPosts.length > 0) {

            if (url_post_id !== null) {
                setPostSelected(draftPosts.filter(post => post.db_id == url_post_id));
                setAddPostImages(true);
                const site = autoCompleteSiteList.filter(post => post.id == url_site_id);
                setSiteId(site[0]);
            }

        }
    }, [draftPosts]);

   

    useEffect(() => {
        filterList(statusFilter);
    }, [searchInput]);

    const searchChangeHandler = (event) => {
        setSearchInput(prevState => { return event.target.value; });

    }

    async function fetchSiteList() {
        let response = await fetch('sites/getsites');
        let data = await response.json();

        setSiteList(data);
        let sites = [{ label: "Select ", id: 0 }];
        data.forEach(site => {

            const option = { label: site.name, id: site.id };
            sites.push(option);
        });
        setAutoCompleteSiteList(sites);
    }

    const statusSelectionHandler = (e) => {

        let status = e.target.value;
        setStatusFilter(status);
        filterList(status);
    }
    const siteSelectionChanged = async (event, value) => {
        //setSiteName(value.label);
        setSiteId(value);

        await fetchDraftPosts(value.id);
    }

    const filterList = (status) => {
        let filteredList = draftPosts.filter(post => post.status < 2);
        let filteredList_new = filteredList;
        if (viewSelected == 1)
            filteredList = draftPosts.filter(post => post.is_google_doc == 1 && post.status < 2); 
        if (viewSelected == 2)
            filteredList = draftPosts.filter(post => (post.content_Editing == 1) && post.is_google_doc != 1 &&  post.status < 2); 

        
        if (viewID == 5) {
            status = 2;
            filteredList_new = draftPosts.filter(post => ((post.status == 1)));
            
        }
        else if (roleId == 6 || viewID == 5) {
            filteredList_new = filteredList.filter(post => post.status < 2);
            
        }
       
        else if (status == 0) {
            filteredList_new = filteredList;
            
        }
        else {
            filteredList_new = filteredList.filter(post => post.status == status);

        }
        console.log("view id" + viewID);
        console.log("role id" + roleId);
        console.log(draftPosts);
        console.log(filteredList_new);
        filteredList_new = filteredList_new.filter(post => post.post_title.toLowerCase().includes(searchInput.toLowerCase()));
        //filteredList.filter(post => post.content_Editing == 1);
        setFilteredDraftList(prevState => {
            return filteredList_new;
        });

       
    }


    async function fetchDraftPosts(id) {
        const data = { 'id': id };
        //let response = await fetch('post/draftposts');
        //let post_data = await response.json();

        try {
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            //const response = await fetch('post/draftposts', config)
            const response = await fetch('post/contenteditingpost', config)
            const json = await response.json()
            if (response.ok) {
                setDraftPosts(json);
            } else {

            }
        } catch (error) {
            console.log(error);
        }
        //setSiteList(data);
    }

    async function addLink(post_selected) {
        try {
            const data = { id: 0, post_url: post_selected[0].post_url, post_title: post_selected[0].post_title, site_id: siteId.id, wp_id: 0, content: '', content_Editing: 1, status: 0, updated_by: userId, is_google_doc: 1 };
            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
            const response = await fetch('post/upadatepost', config)

            if (response.ok) {
                const json = await response.json();

                return json[0].id;
            } else {

            }

        } catch (error) {
            console.log(error);
            return -1;
        }

    }

    const selectPostHandler = async (id) => {


        //console.log(draftPosts.filter(post => post.id == id));
        const post_selected = draftPosts.filter(post => post.id == id);

        if (post_selected[0].db_id == 0) {
            const newPostId = await addLink(post_selected);
            post_selected[0].db_id = newPostId;
            setDraftPosts((prevState) => {
                const i = prevState.findIndex((oldRow) => {
                    return oldRow.id == id;
                });
                prevState[i].db_id = newPostId;
                return [...prevState];
            });
            setPostSelected(post_selected);
            setAddPostImages(true);
            //window.history.replaceState(null, "New Page Title", "/AddPostImages?postid=".id);
            //history.push('/AddPostImages?postid=' . id, { shallow: true });
            navigate('/EditPostContent?postid=' + newPostId + '&siteid=' + post_selected[0].site_id);
        }
        else {
            setPostSelected(draftPosts.filter(post => post.id == id));
            setAddPostImages(true);
        }

        navigate('/EditPostContent?postid=' + post_selected[0].db_id + '&siteid=' + post_selected[0].site_id);

    }

    const modalCloseHandler = () => {
        setAddPostImages(false);
        if (roleId == 5 || viewID == 5) {
            navigate('/ApproveContentPosts');
        }
        else {
            navigate('/ContentPosts');
        }
        setSiteId(siteId);

    }

    const onNextPostHandler = () => {

        let i = filteredDraftList.findIndex((row) => {
            return row.db_id == postSelected[0].db_id;
        });

        let new_post = [];
        if (filteredDraftList.length - 1 > i) {
            new_post[0] = filteredDraftList[i + 1];
        }
        else {
            new_post[0] = filteredDraftList[0];
        }

        setPostSelected(prevPostSelected => ({
            ...new_post
        }));
        /*setTimeout(() => {
           
        }, 100);*/
        //console.log("Post selection changed");
        //console.log(new_post);
        navigate('/EditPostContent?postid=' + new_post[0].db_id + '&siteid=' + new_post[0].site_id);
    }

    const onPostListUpdate = () => {
        fetchDraftPosts(siteId.id);
    }

    const onPrevPostHandler = () => {
        let i = filteredDraftList.findIndex((row) => {
            return row.db_id == postSelected[0].db_id;
        });
        let new_post = [];
        if (i > 0) {
            new_post[0] = filteredDraftList[i - 1];
        }
        else {
            new_post[0] = filteredDraftList[filteredDraftList.length - 1];
        }

        setPostSelected(new_post);
        navigate('/EditPostContent?postid=' + new_post[0].db_id + '&siteid=' + new_post[0].site_id);
    }

    const onCloseModal = () => {
        setShowSearchPopUp(prevState => { return false; });
        setShowAddNewLinkPopup(false);
    }

    const contentAddedHandler = () => {
        setShowSearchPopUp(prevState => { return false; });
        fetchDraftPosts(siteId.id);
    }

    const showPostHandler = (event) => {
        event.preventDefault();
        setShowSearchPopUp(false);
        setShowSearchPopUp(true);
    }
    const showAddNewLinkPopupHandler = () => {
        setShowAddNewLinkPopup(true);
    }

    const setViewForPage = (event) => {
        setViewSelected(prevState => {
            return event.target.value;
        });
        filterList(0);
        
    }
    const siteOptions = siteList.map(site => <option value={site.id} >{site.name}</option>);

    return (
        

        
        <>
            <>
                {viewSelected == 0 && <div className="row">
                    <div className="col">
                        <input type="radio" name="viewOption" value="1" onClick={setViewForPage} ></input> Google Docs
                        &nbsp;&nbsp;&nbsp;
                        <input type="radio" name="viewOption" value="2" onClick={setViewForPage} ></input> Select Existing Posts
                    </div>
                </div>}
            </>

            {viewSelected != 0 &&
                <>    
                {addPostImages == false &&
                    <>
                        <Card>
                            <div >
                                <h3>Select Posts for Content Editing</h3>
                                <div className="row">
                                    <div className="col">

                                        <Autocomplete
                                            autoSelect={true}
                                            disablePortal
                                            disableClearable
                                            id="combo-box-demo"
                                            value={siteId}
                                            inputValue={siteIDSelected}
                                            onInputChange={(event, newInputValue) => {
                                                setSiteIDSelected(newInputValue);
                                            }}
                                            //isOptionEqualToValue={(option, siteId) => option.id === siteId.id}
                                            options={autoCompleteSiteList}
                                            sx={{ width: 300 }}
                                            onChange={siteSelectionChanged}
                                            renderInput={(params) => <TextField {...params} label="Select Site" />}
                                        />
                                    </div>
                                    <div className="col">
                                        {roleId == 4 && <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={statusFilter}
                                            label=""
                                            onChange={statusSelectionHandler}
                                            sx={{ width: 300 }}
                                        >
                                            <MenuItem value={0}>All</MenuItem>
                                            <MenuItem value={1}>Not submitted</MenuItem>
                                            <MenuItem value={2}>Submitted</MenuItem>
                                            <MenuItem value={3}>Approved</MenuItem>
                                            <MenuItem value={4}>Rejected</MenuItem>
                                            <MenuItem value={5}>Completed</MenuItem>
                                        </Select>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                    <input className="form-control" onChange={searchChangeHandler} name="searchBy" placeholder="Search By Title" ></input>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">

                                    {viewSelected == 2 && <button type="button" className="btn btn-primary" onClick={showPostHandler} >Search For Post</button>}
                                    {viewSelected == 1 && <button className="btn btn-primary" onClick={showAddNewLinkPopupHandler} >Add new link</button>}
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <br></br>

                        <DraftPostColumns data={filteredDraftList} onSelectPost={selectPostHandler} />
                        {showSearchPopUp && <SelectionPopUp Posts={draftPosts} siteId={siteId.id} onSelectPost={selectPostHandler} contentAdded={contentAddedHandler} onCloseModal={onCloseModal} />}
                        {showAddNewLinkPopup && <AddNewLinkPopUp onCloseModal={onCloseModal} siteSelected={siteId} sites={autoCompleteSiteList} contentAdded={contentAddedHandler} />}
                    </>
                }

                {addPostImages && <EditPostContent postDetails={postSelected} siteName={siteId.label} onNextPost={onNextPostHandler} onPrevPost={onPrevPostHandler} siteId={siteId.id} onCloseModal={modalCloseHandler} roleId={roleId} viewID={viewID} updatePostList={onPostListUpdate} ></EditPostContent>}
            </>
            }
        </>
    );
}

export default ContentEditing;